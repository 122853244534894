import React, { useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useAppContext } from '../context/appContext';

const SmallMenu = () => {
  const { toggleMenu, showMenu } = useAppContext();

  return (
    <Wrapper>
      <div className={showMenu ? 'menu' : 'menuGone'}>
        <ul className='nav-items'>
          <li className='nav-item'>
            <NavLink to='/' onClick={toggleMenu}>
              Home
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink to='/about' onClick={toggleMenu}>
              About
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink to='/skills' onClick={toggleMenu}>
              Skills
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink to='/portfolio' onClick={toggleMenu}>
              Portfolio
            </NavLink>
          </li>
          <li className='nav-item'>
            <NavLink to='/contact' onClick={toggleMenu}>
              Contact
            </NavLink>
          </li>
        </ul>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  z-index: 99;
  .menu {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    opacity: 1;
    transition: all ease 0.3s;
  }
  .menuGone {
    display: none;
  }

  .nav-items {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: black;
    opacity: 0.85;
    text-align: center;

    .active {
      background-color: var(--darkColor);
      background-color: var(--secondColor);
      color: var(--darkColor);
    }
    li {
      display: block;
      width: 100%;

      a {
        display: block;
        width: 100%;
        padding: 8px 0;
        position: relative;
        z-index: 9;
        text-transform: uppercase;
        letter-spacing: 1px;

        &:hover {
          cursor: pointer;
          background-color: var(--secondColor);
          color: var(--darkColor);
          transition: 0.2s all cubic-bezier(0.61, 0.02, 0.56, 0.97);
        }
      }
      a:hover::before {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media only screen and (min-width: 764px) {
    display: none;
  }
`;

export default SmallMenu;
