import React from 'react';
import styled from 'styled-components';
import Progressbar from './Progressbar';

const Skills = () => {
  return (
    <Wrapper>
      <Progressbar title='HTML5' text={'80%'} width={'80%'} />
      <Progressbar
        title='CSS, SCSS, Styled-components'
        text={'70%'}
        width={'70%'}
      />
      <Progressbar title='Javascript' text={'60%'} width={'60%'} />
      <Progressbar title='ReactJS' text={'70%'} width={'70%'} />
      <Progressbar title='NodeJS / express' text={'40%'} width={'40%'} />
      <Progressbar title='MongoDB / mongoose' text={'70%'} width={'70%'} />
      <Progressbar title='Design' text={'40%'} width={'40%'} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;

  @media screen and (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;

export default Skills;
