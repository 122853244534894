import React from 'react';
import styled from 'styled-components';

const PortfolioCard = ({ title, text, img, link, tags }) => {
  return (
    <Wrapper>
      <a href={link}>
        <img src={img} alt='' />
        <div className='cardBody'>
          <h3>{title}</h3>
          <p>{text}</p>
          <div className='tags'>
            {tags.map((tag) => {
              return <p className='tag'>{tag}</p>;
            })}
          </div>
        </div>
      </a>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  /* max-width: 300px; */
  background-color: var(--darkColor);
  box-shadow: var(--cardShadow);
  transition: all ease 0.3s;
  img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  .cardBody {
    height: calc(100% - 150px);
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    h3 {
      color: var(--secondColor);
    }
  }

  .tags {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin: 10px 0;

    .tag {
      text-align: center;
      font-size: 16px;
      background-color: var(--secondColor);
      color: var(--mainColor);
      padding: 4px 6px;
      width: 100%;
    }
  }
  &:hover {
    transform: scale(1.02);
    h3 {
      color: var(--thirdColor);
    }
    .tag {
      background-color: var(--thirdColor);
    }
  }
`;

export default PortfolioCard;
