import React, { useEffect, useState } from 'react';
import Sidebar from './components/Sidebar';
import styled from 'styled-components';
import HomePage from './pages/HomePage';
import { Route, Routes } from 'react-router-dom';
import AboutPage from './pages/AboutPage';
import SkillsPage from './pages/SkillsPage';
import PortfolioPage from './pages/PortfolioPage';
import ContactPage from './pages/ContactPage';
import { GiHamburgerMenu } from 'react-icons/gi';
import SmallMenu from './components/SmallMenu';
import { useAppContext } from './context/appContext';

function App() {
  const { toggleMenu, showMenu } = useAppContext();
  return (
    <Wrapper>
      <SmallMenu />
      <Sidebar />
      <div className='hamburger'>
        <GiHamburgerMenu className='burger' onClick={toggleMenu} />
      </div>
      <main className='mainContent'>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/about' element={<AboutPage />} />
          <Route path='/skills' element={<SkillsPage />} />

          <Route path='/portfolio' element={<PortfolioPage />} />

          <Route path='/contact' element={<ContactPage />} />
        </Routes>
      </main>
    </Wrapper>
  );
}

const Wrapper = styled.main`
  display: flex;
  overflow: hidden;
  position: relative;

  // Hamburger Menu
  .hamburger {
    position: absolute;
    right: 40px;
    top: 20px;
    z-index: 99;

    .burger {
      font-size: 2rem;
      color: var(--secondColor);

      &:hover {
        color: var(--thirdColor);
        cursor: pointer;
      }
    }
    @media screen and (min-width: 764px) {
      display: none;
    }
  }

  // Main Content
  .mainContent {
    padding: 20px;
    height: 100vh;
    overflow-y: scroll;
    width: 100%;

    &::-webkit-scrollbar {
      width: 10px;
      background-color: var(--mainColor);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: var(--secondColor);
    }
    &::-webkit-scrollbar-track {
      border-radius: 2px;
      background-color: var(--darkColor);
    }
  }
`;

export default App;
