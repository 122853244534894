import React from 'react';
import styled from 'styled-components';
import Skills from '../components/skills/Skills';
import Title from '../components/Title';

const SkillsPage = () => {
  return (
    <Wrapper>
      <Title text={`Skills`} />
      <Skills />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

export default SkillsPage;
