import React, { useState } from 'react';
import styled from 'styled-components';

const ContactForm = () => {
  const [message, setMessage] = useState('');

  const onChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Wrapper>
      <form className='form' onSubmit={handleSubmit}>
        <input type='email' placeholder='Email' />
        <textarea
          placeholder='Message'
          name='message'
          onChange={onChange}
        ></textarea>
        <a
          href={`mailto:chrisnyl.336@gmail.com?Subject=From the portfolio&body=${message}`}
          className='alink'
        >
          <button type='button' className='formBtn'>
            Send
          </button>
        </a>
      </form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  form {
    padding: 20px;
    border-radius: 10px;
    background-color: var(--darkColor);
    display: flex;
    flex-direction: column;

    input {
      margin-bottom: 10px;
      outline: transparent;
      padding: 10px;
    }

    textarea {
      padding: 10px;
      resize: vertical;
      margin-bottom: 10px;
      outline: transparent;
    }

    button {
      padding: 4px 12px;
      width: 100%;
      background-color: var(--secondColor);
      border-radius: 4px;
      transition: all ease 0.3s;

      &:hover {
        background-color: var(--thirdColor);
        cursor: pointer;
      }
    }
  }
`;

export default ContactForm;
