import minNettside from '../img/minnettside.jpg';
import phil from '../img/philm.jpg';
import alien from '../img/alienmeditation.jpg';
import tarot from '../img/tarotcard.jpg';
import certificate from '../img/certificate.jpg';
import rate from '../img/rateyourday.jpg';
import itzel from '../img/itzelmoon.jpg';
import lokalhjelp from '../img/lokalhjelp.jpg';
import bloodSugar from '../img/item3.png';

export const websites = [
  {
    id: 1,
    title: 'MinNettside',
    text: 'I have made everything on this website',
    img: minNettside,
    link: 'https://minnettside.com/',
    category: 'Javascript',
    tags: ['HTML', 'CSS', 'Javascript'],
  },
  {
    id: 2,
    title: 'Phil Anthony M',
    text: 'I created this website after instructions from Phil.',
    img: phil,
    link: 'https://philanthonym.com/',
    category: 'Wordpress',
    tags: ['Wordpress', 'CSS'],
  },
  {
    id: 3,
    title: 'Alienmeditation',
    text: 'I am fixing bugs, taking care of the host, and helping out with SEO.',
    img: alien,
    link: 'https://alienmeditation.com/',
    category: 'Wordpress',
    tags: ['Wordpress', 'CSS', 'SEO'],
  },
  {
    id: 4,
    title: 'Random Tarot Card',
    text: 'This card "game" gives one random card daily.',
    img: tarot,
    link: 'https://freetarotreadings.alienmeditation.com',
    category: 'Javascript',
    tags: ['HTML', 'CSS', 'Javascript'],
  },
  {
    id: 5,
    title: 'Certificate Generator',
    text: 'Website that generate one certificate per email and save the user in MongoDB.',
    img: certificate,
    link: 'https://certificate-generator-en.herokuapp.com/',
    category: 'ReactJS',
    tags: ['ReactJS', 'SCSS', 'MongoDB'],
  },
  {
    id: 6,
    title: 'Rate your day',
    text: 'Rate your day app. I made it with javascript and I have a version that I made with react native as well.',
    img: rate,
    link: 'https://christoffern5.sg-host.com/',
    category: 'ReactJS',
    tags: ['ReactJS', 'SCSS', 'MongoDB'],
  },
  {
    id: 7,
    title: 'ItzelMoon',
    text: 'I made the design for this website with HTML, SCSS and Javascript',
    img: itzel,
    link: 'https://itzelmoon.com/',
    category: 'Javascript',
    tags: ['HTML', 'SCSS', 'Javascript'],
  },
  {
    id: 8,
    title: 'LokalHjelp',
    text: 'Simple website created with HTML, CSS and Javascript',
    img: lokalhjelp,
    link: 'https://lokalhjelp.com/',
    category: 'Javascript',
    tags: ['HTML', 'SCSS', 'Javascript'],
  },
  {
    id: 9,
    title: 'Blood Sugar Academy',
    text: 'Wordpress website that were made after instructions from the client',
    img: bloodSugar,
    link: 'https://lokalhjelp.com/',
    category: 'Javascript',
    tags: ['Wordpress', 'Elementor', 'CSS'],
  },
];
