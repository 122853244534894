import React from 'react';
import styled from 'styled-components';
import { BsWhatsapp, BsMailbox } from 'react-icons/bs';

const ContactInfo = () => {
  return (
    <Wrapper>
      {/*Card*/}
      <a href='https://wa.me/4797513800'>
        <div className='card'>
          <div className='cardIcon'>
            <BsWhatsapp />
          </div>
          <div className='cardContent'>
            <h3>Whatsapp</h3>
            <p>+47 975 13 800</p>
          </div>
        </div>
      </a>
      {/*Card*/}
      <a href='mailto:chrisnyl.336@gmail.com'>
        <div className='card'>
          <div className='cardIcon'>
            <BsMailbox />
          </div>
          <div className='cardContent'>
            <h3>Email</h3>
            <p>chrisnyl.336@gmail.com</p>
          </div>
        </div>
      </a>
      {/*Card*/}
      {/* <div className='card'>
        <div className='cardIcon'>
          <h2>ICON</h2>
        </div>
        <div className='cardContent'>
          <h3>Whatsapp</h3>
          <p>+47 975 13 800</p>
        </div>
      </div> */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 20px;
  .card {
    background-color: var(--darkColor);
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 10px;
    display: flex;
    transition: all ease 0.3s;

    .cardIcon {
      border: 2px solid var(--secondColor);
      display: flex;
      align-items: center;
      justify-content: center;
      /* padding: 10px; */
      border-radius: 10px;
      overflow: hidden;

      svg {
        /* background-color: white; */
        padding: 10px;
        height: 100%;
        width: 100%;
        font-size: 2rem;
        color: var(--secondColor);
      }
    }

    .cardContent {
      margin-left: 10px;

      h3 {
        color: var(--secondColor);
      }
      p {
        /* color: var(--secondColor); */
      }
    }

    &:hover {
      transform: translateY(-5px);

      .cardIcon {
        border-color: var(--thirdColor);
      }

      svg {
        color: var(--thirdColor);
      }

      .cardContent {
        h3 {
          color: var(--thirdColor);
        }
        p {
          color: white;
        }
      }
    }
  }

  @media screen and (max-width: 450px) {
    .card {
      text-align: center;
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
      .cardIcon {
        width: 50px;
        margin-bottom: 10px;
      }
    }
  }
`;

export default ContactInfo;
