import React from 'react';
import styled from 'styled-components';
import Particle from '../components/particles/Particle';

const HomePage = () => {
  return (
    <Wrapper>
      <div className='p-particles-js'>
        <Particle />
      </div>
      <div className='typography'>
        <h1>
          Hi, I'm <span>Christoffer Nyland</span>
        </h1>
        <p>I am a self-taugth web developer looking for work</p>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  .p-particles-js {
    position: absolute;
    top: 0;
    left: 0;
  }

  .typography {
    width: 60%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }

  @media screen and (max-width: 500px) {
    h1 {
      font-size: 3rem;
      span {
        font-size: 3rem;
      }
    }
    .typography {
      width: 100%;
    }
  }
  @media screen and (min-width: 999px) {
    .typography {
      width: 80%;
    }
  }
`;

export default HomePage;
