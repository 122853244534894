import React from 'react';
import styled from 'styled-components';
import avatar from '../../img/Christoffer.jpg';
import { NavLink } from 'react-router-dom';

const Navigation = () => {
  return (
    <Wrapper>
      <div className='avatar'>
        <img src={avatar} alt='' />
      </div>
      <ul className='nav-items'>
        <li className='nav-item'>
          <NavLink to='/'>Home</NavLink>
        </li>
        <li className='nav-item'>
          <NavLink to='/about'>About</NavLink>
        </li>
        <li className='nav-item'>
          <NavLink to='/skills'>Skills</NavLink>
        </li>
        <li className='nav-item'>
          <NavLink to='/portfolio'>Portfolio</NavLink>
        </li>
        <li className='nav-item'>
          <NavLink to='/contact'>Contact</NavLink>
        </li>
      </ul>
      <footer className='footer'>
        <p>&copy; 2022 Christoffer Nyland</p>
      </footer>
    </Wrapper>
  );
};

const Wrapper = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  border-right: 2px solid var(--darkColor);
  border-right: 2px solid var(--mainColor);

  .avatar {
    width: 100%;
    text-align: center;
    /* border-bottom: 1px solid var(--darkColor);
    border-bottom: 1px solid var(--mainColor); */
    /* padding: 20px; */
    /* background-color: var(--mainColor); */

    img {
      width: 100%;
      height: 400px;
      /* width: 200px;
      height: 200px; */
      object-fit: cover;
      object-position: 20% 30%;
      /* border-radius: 50%; */
      /* border: 6px solid var(--darkColor); */
      /* border: 6px solid var(--mainColor); */
    }
  }
  // Nav Items
  .nav-items {
    width: 100%;
    text-align: center;

    .active {
      background-color: var(--darkColor);
      background-color: var(--secondColor);
      color: var(--darkColor);
    }
    li {
      display: block;

      a {
        display: block;
        width: 100%;
        padding: 8px 0;
        position: relative;
        z-index: 9;
        text-transform: uppercase;
        letter-spacing: 1px;

        &:hover {
          cursor: pointer;
          background-color: var(--secondColor);
          color: var(--darkColor);
          transition: 0.2s all cubic-bezier(0.61, 0.02, 0.56, 0.97);
        }
      }
      a:hover::before {
        width: 100%;
        height: 100%;
      }
    }
  }

  .footer {
    width: 100%;
    background-color: var(--mainColor);
    padding: 16px 4px;
    text-align: center;
  }
`;

export default Navigation;
