import React from 'react';
import styled from 'styled-components';

const Progressbar = ({ title, text, width }) => {
  return (
    <Wrapper>
      <h5>{title}</h5>
      <div className='progressbar'>
        <div className='progress'>
          <p>{text}</p>
          <span style={{ width: width }}></span>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  h5 {
    /* color: var(--secondColor); */
    margin-bottom: 5px;
  }
  .progressbar {
    /* border: 2px solid black; */
    box-shadow: var(--cardShadow);
    display: flex;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;

    .progress {
      position: relative;
      height: 35px;
      width: 100%;
      background-color: var(--darkColor);

      p {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: var(--darkColor);
        font-weight: bold;
        z-index: 1;
        margin-left: 10px;
      }

      span {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 100%;
        background-color: var(--secondColor);
      }
    }
  }
`;

export default Progressbar;
