import React, { useReducer, useContext } from 'react';
import reducer from './reducer';

import { TOGGLE_MENU } from './actions';

const initialState = {
  openMenu: false,
};

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const toggleMenu = () => {
    dispatch({ type: TOGGLE_MENU });
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        toggleMenu,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};

export { AppProvider, initialState, useAppContext };
