import { TOGGLE_MENU } from './actions';

import { initialState } from './appContext';

const reducer = (state, action) => {
  if (action.type === TOGGLE_MENU) {
    return {
      ...state,
      showMenu: !state.showMenu,
    };
  }
};

export default reducer;
