import React from 'react';
import styled from 'styled-components';
import Testimonial from '../components/about/Testimonial';
import Title from '../components/Title';
import chris from '../img/chris.jpg';
import english from '../img/english.pdf';
import norsk from '../img/norsk.pdf';

const AboutPage = () => {
  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch(english).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'english.pdf';
        alink.click();
      });
    });
  };
  const onButtonClick2 = () => {
    // using Java Script method to get PDF file
    fetch(norsk).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = 'norsk.pdf';
        alink.click();
      });
    });
  };

  return (
    <Wrapper>
      <div className='pageTitle'>
        <Title text={'About'} />
      </div>
      <div className='aboutSection'>
        <div className='aboutLeft'>
          <img src={chris} alt='' />
          <div className='aboutInfoContainer'>
            <div className='aboutInfo'>
              <h4>CV English</h4>
              <button onClick={onButtonClick} className='btn'>
                <p>Download</p>
                <div className='fill'></div>
              </button>
            </div>
            <div className='aboutInfo'>
              <h4>CV Norsk</h4>
              <button className='btn' onClick={onButtonClick2}>
                <p>Last Ned</p>
                <div className='fill'></div>
              </button>
            </div>
            <div className='aboutInfo'>
              <h4>Nationality:</h4>
              <h5>Norwegian</h5>
            </div>
            <div className='aboutInfo'>
              <h4>Birthday:</h4>
              <h5>13.07.1993</h5>
            </div>
            <div className='aboutInfo'>
              <h4>Location:</h4>
              <h5>Brazil, são gonçalo</h5>
            </div>
            <div className='aboutInfo'>
              <h4>Languages:</h4>
              <h5>English, Norwegian</h5>
            </div>
          </div>
        </div>
        <div className='aboutRight'>
          <div className='aboutText'>
            <h3>
              Hi, my name is <span>Christoffer</span>
            </h3>
            <p>
              I am a self-taught web developer from Norway. For the moment I
              live in Brazil and working as a freelancer where I am mainly
              designing, developing, and maintaining websites, but I also do
              some SEO work and publishing articles.
            </p>
            <p>
              I started my web development journey by creating websites with
              WordPress and Elementor from 2016 - 2020.
            </p>
            <p>
              During this period I studied at NKI (Norsk Kunnskaps-Institutt)
              about digital marketing (G-Analytics, G-Ads, g-tag manager,
              G-search console).
            </p>
            <p>
              I also got into adobe illustrator, video editing, and SEO at the
              time. Even if this is outside web development it has given me
              valuable skills that I still use today when coding.
            </p>
            <h3>My coding experience</h3>
            <p>
              I wanted to learn to code because of the restriction I had when
              creating a website with WordPress. So in late 2020, I got started
              with HTML, CSS, and Javascript.
            </p>
            <p>
              Today I am using most of my free time with Javascript, ReactJS and
              nodeJS. I am also improving my Figma/design skills with focus on
              web and mobile design.
            </p>
          </div>
        </div>
      </div>
      {/*Feedback*/}
      <div className='feedback'>
        <h2>Testimonials</h2>
        <div className='feedbackcards'>
          <Testimonial
            person='Phil Anthony M'
            job={'Motivator'}
            desc={`Christoffer was a dream to work with. High attention to detail. Fast. Accurate. And great communication. I'd 100% recommend working with him.`}
          />
          <Testimonial
            person='Karen Prieto'
            job={'Online Teacher'}
            desc={`As an online school CEO, I was looking for help to get a robust website for an unlimited quantity of students in seven courses available in three different languages. Christoffer helped me to structure a full scheme for the school, including users and enrolment managing, a complete website interface with the payment processing, and a good and safe hosting for my courses. Thanks to his help, now we are ready to promote our courses around the world!`}
          />
          <Testimonial
            person='Tarjei Broch'
            job={'CEO'}
            desc={`Chris har hjulpet meg med flere små-jobber innen wordpress og tekst publisering. Han er lett å ha med å gjøre og har utført jobbene jeg har sendt til han raskt og uten problem`}
          />
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  /* overflow-y: scroll; */
  padding: 10px;
  .pageTitle {
    h2 {
      font-size: 4rem;
    }
  }

  .aboutSection {
    display: flex;
    gap: 20px;

    .aboutLeft {
      flex: 1;
      img {
        width: 100%;
        max-height: 600px;
        object-fit: cover;
      }

      .aboutInfoContainer {
        width: 100%;
        padding: 10px;
        background-color: var(--darkColor);

        .aboutInfo {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 20px;
          margin-bottom: 10px;
          letter-spacing: 1px;

          h5 {
            font-weight: 400;
          }
          &:hover {
            h5 {
              color: var(--thirdColor);
            }
          }

          button {
            background-color: var(--secondColor);
            outline: transparent;
            border: none;

            &:hover {
              /* background-color: var(--thirdColor); */
              cursor: pointer;
            }
          }

          //BTN
          .btn {
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;

            p {
              z-index: 2;
            }

            &:hover {
              .fill {
                transform: translate(30px, -50px);
              }
            }
          }

          .fill {
            transition: all 0.4s ease;
            width: 140%;
            height: 140%;
            background-color: var(--thirdColor);
            border-radius: 40px;
            position: absolute;
            bottom: -50px;
            left: -50px;
          }
        }
      }
    }
    .aboutRight {
      flex: 2;

      .aboutText {
        h3 {
          font-size: 3rem;
          span {
            font-size: 3rem;
            color: var(--thirdColor);
          }
        }
        p {
          line-height: 1.4rem;
          font-size: 20px;
          margin-bottom: 15px;
        }
      }
    }
  }

  .feedback {
    margin-top: 60px;
    padding: 20px 0;

    h2 {
      font-size: 4rem;
      margin-bottom: 20px;
      color: var(--secondColor);
    }

    .feedbackcards {
      display: flex;
      /* flex-direction: column; */
      gap: 20px;
    }
  }

  @media only screen and (max-width: 763px) {
    .aboutSection {
      flex-direction: column-reverse;

      .aboutLeft {
        flex: 1;
        img {
          width: 100%;
        }

        .aboutInfoContainer {
          .aboutInfo {
            grid-template-columns: 1fr;
            /* margin: 0; */
            gap: 0;
          }
        }
      }
      .aboutRight {
        flex: 1;

        h3 {
          span {
            color: var(--thirdColor);
          }
        }
        p {
          font-size: 16px;
        }
      }
    }

    .feedback {
      margin-top: 60px;
      padding: 20px 0;

      h2 {
        font-size: 2.5rem;
      }

      .feedbackcards {
        flex-direction: column;
      }
    }
  }
`;

export default AboutPage;
