import React from 'react';
import styled from 'styled-components';
import Navigation from './navigation/Navigation';

const Sidebar = ({ isOpen }) => {
  return (
    <Wrapper>
      <Navigation />
    </Wrapper>
  );
};
// const Sidebar = ({ isOpen }) => {
//   const sidebarFunc = (open) => {
//     if (open) {
//       return 'translate(0)';
//     } else {
//       return 'translate(-100%)';
//     }
//   };
//   return (
//     <Wrapper style={{ transform: sidebarFunc(isOpen) }}>
//       <Navigation />
//     </Wrapper>
//   );
// };

const Wrapper = styled.div`
  z-index: 9999;
  width: 350px;
  height: 100vh;
  overflow-y: scroll;
  background-color: var(--darkColor);
  transition: all ease 0.3s;

  &::-webkit-scrollbar {
    width: 10px;
    background-color: var(--mainColor);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: var(--secondColor);
  }
  &::-webkit-scrollbar-track {
    border-radius: 2px;
    background-color: var(--mainColor);
  }

  @media screen and (max-width: 1200px) {
    /* transform: translateX(-100%); */
  }
  @media only screen and (max-width: 763px) {
    display: none;
  }
  /* @media screen and (min-width: 1200px) {
    transform: translateX(0);
  } */
`;

export default Sidebar;
