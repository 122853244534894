import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  :root {
    --mainColor:#042940;
    --secondColor:#9FC131;
    --thirdColor:#DBF227;
    --lightColor:#F1F5F8;
    --darkColor:#031520;
    --background-color:#293642;

    --cardShadow: 0 0 3px 1px black;
  }
  *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-size: 1.2rem;
    text-decoration: none;
    list-style: none;
  }

  body {
    background-color: var(--background-color);
    color: var(--lightColor);
    font-family: 'Roboto Condensed', sans-serif;
  }

  a {
    font-family: inherit;
    color: inherit;
  }

  h1 {
    font-size: 4rem;

    span {
      font-size: 4rem;
      color: var(--thirdColor);
    }
  }
`;

export default GlobalStyle;
