import React, { useState } from 'react';
import styled from 'styled-components';
import PortfolioCard from '../components/portfolio/PortfolioCard';
import Title from '../components/Title';
import { websites } from '../data/websites';

const PortfolioPage = () => {
  const [data, setData] = useState(websites);
  return (
    <Wrapper>
      <Title text='Portfolio' />
      <div className='cardGrid'>
        {data.map((item) => {
          const { id, title, text, img, link, tags } = item;
          return (
            <PortfolioCard
              key={id}
              img={img}
              title={title}
              link={link}
              tags={tags}
              text={text}
            />
          );
        })}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .cardGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
  }

  @media screen and (max-width: 1100px) {
    .cardGrid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  @media screen and (max-width: 550px) {
    .cardGrid {
      grid-template-columns: 1fr;
    }
  }
`;

export default PortfolioPage;
