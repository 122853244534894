import React from 'react';
import styled from 'styled-components';

const Testimonial = ({ person, desc, job }) => {
  return (
    <Wrapper>
      <h3>{person}</h3>
      <h4>{job}</h4>
      <p>{desc}</p>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 20px;
  background-color: var(--darkColor);
  border-radius: 20px;
  box-shadow: var(--cardShadow);
  transition: all 0.3s ease;

  h4 {
    transition: all 0.3s ease;
    color: var(--secondColor);
  }

  &:hover {
    transform: translateY(-5px);
    h4 {
      color: var(--thirdColor);
    }
  }
`;

export default Testimonial;
