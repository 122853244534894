import React from 'react';
import styled from 'styled-components';
import ContactForm from '../components/contact/ContactForm';
import ContactInfo from '../components/contact/ContactInfo';
import Title from '../components/Title';

const ContactPage = () => {
  return (
    <Wrapper>
      <Title text='Contact' />
      <div className='pageContent'>
        <ContactForm />
        <ContactInfo />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .pageContent {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    /* display: flex; */
    align-items: center;
  }
  @media screen and (max-width: 1050px) {
    .pageContent {
      grid-template-columns: 1fr;
    }
  }
  @media screen and (max-width: 450px) {
    .pageContent {
      display: block;
    }
  }
`;

export default ContactPage;
