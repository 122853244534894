import React from 'react';
import styled from 'styled-components';

const Title = ({ text, span, color }) => {
  return (
    <Wrapper style={color && { color: 'var(--thirdColor)' }}>
      {text}
      {span && span}
      <div className='line'></div>
    </Wrapper>
  );
};

const Wrapper = styled.h2`
  font-size: 4rem;

  .line {
    height: 4px;
    background-color: var(--mainColor);
    margin: 10px 0 20px;
  }

  @media screen and (max-width: 450px) {
    font-size: 2.5rem;
  }
`;

export default Title;
